import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Content from '../Content'

const AboutPageTemplate = ({ meta_title, meta_description, title, team, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className='flex flex-col bg-indigo-100'>
      <div className='container px-8 mx-auto'>
        <Helmet>
          <title>{meta_title}</title>
          <meta name='description' content={meta_description} />
        </Helmet>

        <main className='flex flex-wrap items-center py-12 my-12 text-center text-gray-800'>
          <div className='w-full whitespace-pre-line md:w-full'>
            <h1 className='mb-6 text-5xl leading-tight'>
              {title}
            </h1>

            <PageContent className='mb-6 markdown' content={content} />
          </div>
        </main>

        {/* Team */}
        <h2 className='m-12 text-4xl leading-tight text-center text-gray-800'>The Team</h2>

        <div className='flex flex-wrap justify-center'>
          {team.slice(0, 3).map(TeamMemberBig)}
          {team.slice(3).map(TeamMemberSmall)}
        </div>

      </div>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate

const TeamMemberBig = ({ name, img, title, short_description, url }) => (
  <a key={name} href={url} target='_blank' rel='noopener noreferrer' className='flex items-stretch w-full max-w-xs p-6 md:w-1/3'>
    <div className='flex-1 overflow-hidden bg-gray-100 rounded shadow-md transition transition-shadow ease-in-out hover:shadow-xl duration-500'>
      <div className='w-full h-64 overflow-hidden text-center bg-cover rounded-t lg:rounded-t-none lg:rounded-l' style={{ backgroundImage: `url(${img})` }} title={`Picture of ${name}`} />
      <div className='p-6'>
        <h2 className='mb-2 text-xl font-semibold'>{name}</h2>
        <h3 className='mb-2 text-lg font-medium'>{title}</h3>
        <p className='text-sm text-gray-700 whitespace-pre-line'>{short_description}</p>
      </div>
    </div>
  </a>
)

const TeamMemberSmall = ({ name, img, title, short_description, url }) => (
  <a key={name} href={url} target='_blank' rel='noopener noreferrer' className='flex items-stretch w-full max-w-xs p-6 md:w-1/3'>
    <div className='flex-1 overflow-hidden bg-gray-100 rounded shadow-md transition transition-shadow ease-in-out hover:shadow-xl duration-500'>
      <div className='w-full h-64 overflow-hidden text-center bg-cover rounded-t lg:rounded-t-none lg:rounded-l' style={{ backgroundImage: `url(${img})` }} title={`Picture of ${name}`} />
      <div className='px-6 py-4'>
        <div className='mb-2 text-xl font-semibold'>{name}</div>
        <div className='mb-2 text-lg font-medium'>{title}</div>
        <p className='text-base text-gray-700 whitespace-pre-line'>
          {short_description}
        </p>
      </div>
    </div>
  </a>
)
